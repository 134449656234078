.header-image-form {
    max-width: 100%;
    /* Make image fill the container's width */
    max-height: 50vh;
    /* Limit the image height to the viewport height */
    object-fit: cover;
    /* Ensure the image covers the container */
    border-radius: 12px;
    /* Apply your border radius */
}

.form-h2 {
    font-family: 'Playfair Display', serif;
    margin-bottom: 0.5em;
    font-size: 4vw;
    color: #000000;
}

.iframe-ota-yhteytta {
    width: 100%;
    height: 100%;
}

@media (min-width: 992px) {
    .header-image-form {
        max-height: 100vh;
    }
    .container-form {
        min-height: 100vh;
    }
}

@media (max-width: 992px) {
    .form-h2 {
        font-size: 30px;
    }

    .iframe-ota-yhteytta {
        height: 700px;
    }
}