@-webkit-keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.fade-in-container {
  opacity: 0;
  -webkit-animation: fadeIn 2s ease-in-out forwards;
  animation: fadeIn 2s ease-in-out forwards;
  will-change: opacity;
}