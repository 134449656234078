.header-image-branditarina {
    max-width: 100%;
    /* Make image fill the container's width */
    max-height: 50vh;
    /* Limit the image height to the viewport height */
    object-fit: cover;
    /* Ensure the image covers the container */
    border-radius: 12px;
    /* Apply your border radius */
}

.branditarina-h2 {
    font-family: 'Playfair Display', serif;
    font-size: 30px;
    color: #000000;
}

.branditarina-text {
    font-family: 'Roboto', serif;
    font-size: 14px;
    color: #000000;
    max-width: 80%;
}

.left-content-branditarina {
    margin-left: 40px;
}

@media (min-width: 992px) {
    .header-image-branditarina {
        max-height: 100vh;
    }
}