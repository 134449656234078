.header-image-brandipodi {
    max-height: 60vh;
    max-width: 90%;
    object-fit: cover;
    border-radius: 12px;
    transition: transform 0.3s ease-in-out;
}

.header-image-brandipodi:hover {
    transform: scale(1.05);
  }


.brandipodi-h2 {
    font-family: 'Playfair Display', serif;
    font-size: 30px;
    color: #000000;
    text-align: center;
    padding: 20px;
}

.brandipodi-text {
    font-family: 'Roboto', serif;
    font-size: 16px;
    color: #000000;
    max-width: 60%;
}

.left-content-brandipodi {
    align-items: center;
}

.fa-spotify {
    color: #1ED760;
}

.fa-podcast {
    background: linear-gradient(to bottom, #f452ff,#832bc1);
    color: #fffcf7;
    padding: 8px;
    border-radius: 6px;
}

.fa-youtube {
    color: #FF0000;
}

.social-icon {
    transition: transform 0.3s ease-in-out;
}

.icon-row {
    width: 60%;
}

.soundbar-col {
    width: 60%;
}

.soundbar-gif {
    object-fit: contain;
    max-width: 35%;
}

.social-icon:hover {
    transform: scale(1.2);
  }

@media (min-width: 992px) {
}

@media (max-width: 992px) {
    .brandipodi-text {
        max-width: 100%;
    }
}