.hero-image {
    width: auto;
    max-height: 100vh;
    height: auto;
    padding: 25px;
    border-radius: 50px;
    object-fit: cover;

}

.hero-text-h2 {
    font-family: 'Playfair Display', serif;
    font-size: 30px;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    color: #000000;
}

.hero-text-p {
    font-family: 'Roboto', serif;
    font-style: italic;
    font-size: 14px;
    max-width: 500px;
    color: #000000;
}

.hero-signature {
    font-size: 33px;
    font-family: 'Tangerine', serif;
}

.tutustu-button.btn {
    background-color: #fffcf7;
    border: 2px solid #000000;
    color: #000000;
    font-family: 'Roboto', serif;
    padding: 15px 40px;
    font-weight: 600;
    transition: background-color 0.5s;
}

.tutustu-button.btn:hover {
    background-color: #bd9362;
    border: 2px solid #000000;
    color: #000000;
}

.tutustu-button.btn:active {
    --bs-btn-active-bg: #bd9362;
    --bs-btn-active-border-color: #000000;
    --bs-btn-active-color: #000000;
}

.hero-tutustutaanko-lisaa {
    color: #000000;
    font-family: 'Roboto', serif;
    font-size: 15px;
    margin-left: 35px;
}

@media (max-width: 992px) {

    .brändiuudistuskuva {
        width: 30px;
    }

    .hero-text-p {
        max-width: 100%;
    }

    .tutustu-button {
        margin-bottom: 20px;
    }

    .hero-image {
        max-height: 50vh;
    }

    .hero-text-h2 {
        text-align: center;
    }
}