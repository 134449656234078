
.contact-h2 {
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    padding: 20px;
    font-size: 30px;
    color: #000000;
}

.instagram-icon {
   color: #bd9362;
   transition: color 0.3s ease;
}

.instagram-icon:hover {
    color:#000000;
}

.iframe-contact {
    width: 100%;
    min-height: 300px;
}

@media (max-width: 992px) {
    
    .ig-div {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .iframe-div {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .contact-container {
        min-height: 100vh;
    }
    .ig-div {
        min-height: 100%;
    }
    .iframe-div {
        min-height: 100%;
    }
}