.navbar-border {
    border-bottom: 2px solid #ccc;
    background-color: #fffcf7;
}

.nav-item-navbar {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    font-size: 16px;
    padding: 10px 0;
    color: #000000;
    text-decoration: none;
    transition: color 0.6s;
}