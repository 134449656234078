  /* Footer Section */
  .footer {
      background-color: #bd9362;
      padding: 20px 0px;
      color: #fffcf7;
      font-family: 'Roboto', serif;
  }

  .linkPrivacyPolicy {
      color: #fffcf7;
  }

  .linkPrivacyPolicy:hover {
      cursor: pointer;
  }

  .links {
    color: #fffcf7;
  }

  .logo-image-footer {
    width: auto;
     max-height: 30px;
}