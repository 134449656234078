.brandiuudistus-image {
    width: auto;
    max-height: 90vh;
    height: auto;
    padding: 25px;
    border-radius: 24px;
}

.brandiuudistus-h2 {
    font-family: 'Playfair Display', serif;
    font-size: 30px;
    margin-bottom: 1.5em;
    color: #000000;
}

.brandiuudistus-text-p {
    font-family: 'Roboto', serif;
    font-size: 14px;
    max-width: 80%;
    color: #000000;
}

.brandiuudistus-tutustu-button.btn {
    background-color: #fffcf7;
    border: 2px solid #000000;
    color: #000000;
    font-family: 'Roboto', serif;
    padding: 5px 40px;
    font-weight: 600;
    transition: background-color 0.5s;
    /* Smooth transition */
}

.brandiuudistus-tutustu-button.btn:hover {
    background-color: #bd9362;
    border: 2px solid #000000;
    color: #000000;
}

.brandiuudistus-tutustu-button.btn:active {
    --bs-btn-active-bg: #bd9362;
    --bs-btn-active-border-color: #000000;
    --bs-btn-active-color: #000000;
}

.brandiuudistus-lue-lisaa {
    color: #000000;
    font-family: 'Roboto', serif;
    font-size: 15px;
    margin-left: 35px;
}

@media (max-width: 992px) {

    .brändiuudistuskuva {
        width: 30px;
    }

    .brandiuudistus-text-p {
        max-width: 100%;
    }

    .brandiuudistus-tutustu-button {
        margin-bottom: 20px;
    }
}