.brandiMatka-text-h2 {
    font-family: 'Playfair Display', serif;
    margin-bottom: 0.5em;
    font-size: 30px;
    color: #000000;
}

.brandiMatka-price-text {
    font-family: 'Roboto' serif;
    font-style: italic;
    color: #000000;

}

.brandiMatka-text-p {
    margin-bottom: 3em;
    font-family: 'Roboto', serif;
    font-size: 14px;
    max-width: 500px;
    color: #000000;
}

.brandiMatka-image {
    max-height: 50vh;
    max-width: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.collapsible-container {
    display: flex;
    flex-direction: column;
}

.read-more-text {
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    font-family: 'Roboto' serif;
}



.brandi-matka-button.btn {
    background-color: #fffcf7;
    border: 2px solid #000000;
    color: #000000;
    font-family: 'Roboto', serif;
    font-weight: 600;
    transition: background-color 0.5s;
    width: 200px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.brandi-matka-button.btn:hover {
    background-color: #bd9362;
    border: 2px solid #000000;
    color: #000000;
}

.brandi-matka-button.btn:active {
    --bs-btn-active-bg: #bd9362;
    --bs-btn-active-border-color: #000000;
    --bs-btn-active-color: #000000;
}

.brandi-matka-button-mobile.btn {
    background-color: #fffcf7;
    border: 2px solid #000000;
    color: #000000;
    font-family: 'Roboto', serif;
    font-weight: 600;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    transition: background-color 0.5s;
}

.brandi-matka-button-mobile.btn:hover {
    background-color: #bd9362;
    border: 2px solid #000000;
    color: #000000;
}

.brandi-matka-button-mobile.btn:active {
    --bs-btn-active-bg: #bd9362;
    --bs-btn-active-border-color: #000000;
    --bs-btn-active-color: #000000;
}

@media (min-width: 992px) {
    .brandiMatka {
        min-height: 100vh;
    }

    .left-content-brandi-matka {
        margin-left: 50px;
    }
}