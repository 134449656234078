.brandilupaukset-h2 {
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    padding-top: 20px;
    margin-bottom: 50px;
    font-size: 30px;
    color: #000000;
}

.brandilupaukset-card-title {
    font-family: 'Roboto', serif;
    font-size: 40px;
    color: #000000;
    font-style: bold;
}

.brandilupaukset-card-text {
    font-family: 'Roboto', serif;
    font-size: 16px;
    color: #000000;
}

@media (min-width: 992px) {
    .brandilupaukset-container {
        min-height: 100vh;
    }
}