.feedback-h2 {
    font-family: "Playfair Display", serif;
    font-weight: 500;
    padding: 20px;
    font-size: 30px;
    color: #000000;
    text-align: center;
}

.carousel-wrapper {
    margin: 10px;
    padding: 20px;
}

.carousel-control-prev {
    margin-left: -20px;
}

.carousel-control-next {
    margin-right: -20px;
}

.feedback-card {
    width: 90%;
    max-width: 600px;
    height: 275px;
    padding: 20px;
    background-color: #bd9362;
    border-radius: 15px;
    font-family: "Roboto", serif;
    font-size: 14px;
    color: #fffcf7;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feedback-text {
    font-weight: bold;
    font-style: italic;
}


@media (min-width: 992px) {
    .feedback-card {
        height: 300px;
    }
}