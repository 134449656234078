


.main-title {
    font-size: 5vw;
    color: #000000;
    font-family: 'Playfair Display', serif;
    font-weight: 300;
}

.nav-item {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    font-size: 1.4vw;
    color: #000000;
    text-decoration: none;
    padding: 20px;
    transition: color 0.6s;
    white-space: nowrap;
}

.nav-item:hover {
    color: #bd9362;
}

.header-arrow-text {
    font-family: 'Playfair Display', serif;
    color: #000000;
    font-size: 1.2vw;
    margin-top: 60px;
}

.header-arrow-container {
    margin-right: 50px;

}

.text-image-container {
    display: flex; /* Use flexbox to align text and image horizontally */
    align-items: center;
    overflow: hidden; /* Center text and image vertically */
}

.sub-title {
    color: #000000;
    font-size: 2vw;
    writing-mode: vertical-rl;
    font-family: 'Playfair Display', serif;
    font-weight: 300;
}

.sub-title-mobile {
    font-size: 18px;
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    color: #000000;
}

.right-content .header-video {
    width: auto;
    max-height: 93vh;
}

.kahvitarina-image-header {
    width: 100%;
    max-height: 300px;
    height: auto;
    object-fit: cover;
    object-position: center 70%;
}

.logo-image {
    width: auto;
    max-height: 3vw;
}
.logocol {
    position: absolute;
    padding: 20px;
    cursor: pointer;
}


@keyframes slide-down {
    0% {
      transform: translateY(-150%) rotate(180deg); /* Start off-screen to the left */
    }
    50% {
      transform: translateY(0) rotate(180deg); /* Move to original position */
    }
    100% {
      transform: translateY(150%) rotate(180deg); /* Move off-screen to the right */
    }
  }
  
  .loop-animation { 
    display: inline-block;/* Ensure the element can move */
    animation: slide-down 15s  linear infinite; /* Adjust duration as needed */
  }

@media (max-width: 992px) {
    .mobile-top-space {
        margin-top: 30px;
    }
    .mobile-font-size-h1 {
        font-size: 35px;
    }
  }