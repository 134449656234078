.services-h2 {
    font-family: 'Playfair Display', serif;
    font-size: 30px;
    color: #000000;
}

.small-companies {
    font-family: 'Playfair Display', serif; /* Correctly define the font */
    font-style: italic;
    color: #000000; 
    font-weight: 300;
    padding: 20px;
    font-size: 2.5vw;
}

.service-card-title {
    font-family: 'Playfair Display', serif;
}

.service-card-text {
    font-family: 'Roboto', serif;
    color: #000000;
    min-height: 220px;
}

.service-card-price {
    font-family: 'Roboto', serif;
    color: #000000;
}

.quotation-mark {
    font-size: 7vw;
    margin-top: -30px;
    padding: 0;
}

.lue-lisaa-button.btn {
    background-color: #fffcf7;
    border: 2px solid #000000;
    color: #000000;
    font-family: 'Roboto', serif;
    padding: 5px 40px;
    font-weight: 600;
    transition: background-color 0.5s;
    /* Smooth transition */
}

.lue-lisaa-button.btn:hover {
    background-color: #bd9362;
    border: 2px solid #000000;
    color: #000000;
}

.lue-lisaa-button.btn:active {
    --bs-btn-active-bg: #bd9362;
    --bs-btn-active-border-color: #000000;
    --bs-btn-active-color: #000000;
}

@media (min-width: 992px) {
    .services-h2 {
        margin-left: 50px;
    }
}

@media (max-width: 1200px) {
    .service-card-text {
        min-height: 340px;
    }
}

@media (max-width: 992px) {
    .service-card-text {
        min-height: 0;
    }
}