.topBar {
  background-color: #000000;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 7vh;
  width: 100%;
  overflow: hidden;
}

.topBarText {
  list-style-type: disc; /* Bulletit näkyviin */
  color: #fffcf7;
  font-family: 'Roboto', serif;
  font-size: 22px;
  display: flex;
  animation: slide 60s linear infinite;
  padding: 0; /* Poistaa oletusarvoisen listan sisätilan */
  margin: 0; /* Poistaa oletusarvoisen marginaalin */
}

.topBarText li {
  margin-right: 30px; /* Väli tekstien välillä */
  white-space: nowrap; /* Estää tekstin rivittymisen */
}

@keyframes slide {
  0% {
    transform: translateX(0%); /* Tekstit alkavat oikealta */
  }
  100% {
    transform: translateX(-100%); /* Tekstit liikkuvat kokonaan vasemmalle */
  }
}

@media (max-width: 992px) {
  @keyframes slide {
    0% {
      transform: translateX(0%); /* Tekstit alkavat oikealta */
    }
    100% {
      transform: translateX(-210%); /* Tekstit liikkuvat kokonaan vasemmalle */
    }
  }
}

@media (max-width: 650px) {
  @keyframes slide {
    0% {
      transform: translateX(0%); /* Tekstit alkavat oikealta */
    }
    100% {
      transform: translateX(-700%); /* Tekstit liikkuvat kokonaan vasemmalle */
    }
  }
}