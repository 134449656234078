.omatoimi2-image {
    max-width: 100%;
    max-height: 50vh;
    object-fit: cover;
    border-radius: 12px;
}

.brandiomatoimi-h2 {
    font-family: 'Playfair Display', serif;
    margin-bottom: 0.5em;
    font-size: 30px;
    color: #000000;
}

.brandiomatoimi-price-text {
    font-family: 'Roboto' serif;
    font-style: italic;
    color: #000000;
}

.brandiomatoimi-upcoming-text {
    margin-right: 10px;
}

.brandiomatoimi-text-p {
    margin-bottom: 3em;
    font-family: 'Roboto', serif;
    font-size: 14px;
    max-width: 500px;
    color: #000000;
}

.brandiomatoimi-read-more-text {
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    font-family: 'Roboto' serif;
    text-align: start;
    width: 65%;
}

.collapsible-container-brandiomatoimi {
    display: flex;
    flex-direction: column;
    width: 65%;
}

.brandiomatoimi-button.btn {
    background-color: #fffcf7;
    border: 2px solid #000000;
    color: #000000;
    font-family: 'Roboto', serif;
    font-weight: 600;
    transition: background-color 0.5s;
    width: 200px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.brandiomatoimi-button.btn:hover {
    background-color: #bd9362;
    border: 2px solid #000000;
    color: #000000;
}

.brandiomatoimi-button.btn:active {
    --bs-btn-active-bg: #bd9362;
    --bs-btn-active-border-color: #000000;
    --bs-btn-active-color: #000000;
}

.brandiomatoimi-button1 {
    margin-right: 50px;
}

@media (min-width: 992px) {

    .right-content-brandiomatoimi {
        margin-right: 50px;
    }

    .omatoimi2-image {
        max-height: 100vh;
    }
}

@media (max-width: 992px) {

    .collapsible-container-brandiomatoimi {
        width: 100%;
    }

    .brandiomatoimi-read-more-text {
        text-align: center;
        width: 100%;
    }

    .brandiomatoimi-button.btn {
        margin: 10px;
    }

    .brandiomatoimi-button1 {
        margin-right: 0;
    }
}