.custom-button.btn {
    text-decoration: none;
  }

  .button-text {
    color: #000000;
    font-family: 'Roboto' serif;
    font-size: 18px;
  }
  #collapse-text {
    color: #000000;
    font-family: 'Roboto' serif;
    font-size: 16px;
  }


  .rotate {
    transform: rotate(180deg); /* Rotate icon 180 degrees */
    transition: transform 0.3s ease; /* Smooth transition */
}

.transition {
    transition: transform 0.3s ease; /* Smooth transition for the icon */
    color: #000000;
}